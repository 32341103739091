<template>
  <section class="statisticsFilters">
    <v-md-date-range-picker
      class="statisticsFilters__rangePicker"
      ref="myDatePicker"
      opens="left"
      :startDate="date"
      :endDate="dateTo"
      :max-year="currentYearForFilter"
      min-year="2023"
      @change="handleChange"
      :key="resetCalendar"
    ></v-md-date-range-picker>
    <div class="statisticsFilters__item" @click="$refs.myDatePicker.togglePicker()">
      <iconic name="calendar"></iconic>
      <span>{{ datesInFilter }}</span>
    </div>
    <select class="statisticsFilters__item" v-model="city" @change="changeCity">
      <option value="" selected>Ciudad</option>
      <option v-for="(citie, idx) in $cities" :key="idx" :value="citie">{{ citie }}</option>
    </select>
    <select class="statisticsFilters__item" :disabled="!city" v-model="office" @change="executeFilter">
      <option value="" selected>Sede</option>
      <option v-for="office in offices" :key="office._id" :value="office._id">{{ office.officeName }}</option>
    </select>
  </section>
</template>

<script>
export default {
  data() {
    return {
      city: "",
      office: "",
      date: "",
      dateTo: "",
      resetCalendar: 1,
    };
  },
  computed: {
    offices() {
      if (!this.city) {
        return [];
      }

      return this.$store.getters["offices/getData"].filter((x) => x?.cityName === this.city);
    },
    query() {
      const query = {};

      if (this.city) query.city = this.city;
      if (this.office) query.office = this.office;
      if (this.date) query.date = this.date;
      if (this.dateTo) query.dateTo = this.dateTo;

      return query;
    },
    datesInFilter() {
      let text = `${this.date}`;

      if (this.dateTo) {
        text += ` - ${this.dateTo}`;
      }
      return text;
    },
    currentYearForFilter() {
      return "" + new Date().getFullYear();
    },
  },
  methods: {
    changeCity() {
      this.office = "";
      this.executeFilter();
    },
    executeFilter() {
      this.$router.push({ query: this.query }).catch(() => {});
    },
    verifyDates() {
      this.resetCalendar++;

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      today.setHours(24);

      if (!this.date || (this.date && isNaN(new Date(this.date).getTime()))) {
        let currentDate = new Date(today);

        const initDayWeek = currentDate.getDay() === 0 ? -6 : -currentDate.getDay() + 1;
        currentDate.setDate(currentDate.getDate() + initDayWeek);

        let dateTo = new Date(currentDate);
        dateTo.setDate(currentDate.getDate() + 6);

        if (dateTo >= today) {
          dateTo = new Date(today);
          dateTo.setHours(-24);
        }
        if (currentDate >= dateTo) {
          currentDate = dateTo;
        }

        this.date = currentDate.toISOString().split("T")[0];
        this.dateTo = currentDate === dateTo ? "" : dateTo.toISOString().split("T")[0];

        this.executeFilter();
        return;
      }

      let date = new Date(this.date + "T00:00:00");

      if (this.dateTo) {
        let dateTo = new Date(this.dateTo + "T00:00:00");

        if (dateTo >= today) {
          dateTo = new Date(today);
          dateTo.setHours(-24);
        }
        if (date >= dateTo) {
          date = dateTo;
        }

        const timeDate = date.getTime();
        const timeDateTo = dateTo.getTime();

        if (isNaN(timeDateTo) || timeDate >= timeDateTo) {
          this.dateTo = "";
        } else {
          this.dateTo = dateTo.toISOString().split("T")[0];
        }
      }

      this.date = date.toISOString().split("T")[0];

      this.executeFilter();
    },
    handleChange(values) {
      this.date = values[0]._d.toISOString().split("T")[0];
      this.dateTo = values[1]._d.toISOString().split("T")[0];

      this.verifyDates();
    },
  },
  mounted() {
    const { city, office, date, dateTo } = this.$route.query;

    if (city) this.city = city;
    if (office) this.office = office;
    if (date) this.date = date;
    if (dateTo) this.dateTo = dateTo;

    this.verifyDates();
  },
};
</script>

<style lang="scss">
.statisticsFilters {
  position: relative;
  @include Flex(row, flex-start);
  flex-wrap: wrap;
  gap: 10px;
  min-height: 80px;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  &__item {
    @include Flex(row, flex-start);
    gap: 10px;
    height: 40px;
    width: 100%;
    min-width: 150px;
    padding: 0 15px;
    border: 1px solid #e5e5e5;
    border-radius: 22px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  .mdrp__activator .activator-wrapper {
    display: none;
  }
  .mdrp-root[data-v-7863e830] {
    position: absolute;
    left: 0;
    bottom: 17px;
  }
  @media screen and (min-width: $pocket_width) {
    &__item {
      min-width: 150px;
      width: fit-content;
    }
  }
}
</style>
